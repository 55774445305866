import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'

import { CTA } from '@common/components'
import { MainLayout, NewMainLayout } from '@common/layouts'
import { SEO } from '@components'

export const query = graphql`
  query StrainQuery($id: Int, $type: String) {
    strain(strapiId: { eq: $id }) {
      cannabinoids
      highCbd
      name
      slug
      type
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      childMarkdownRemark {
        excerpt(pruneLength: 200)
        html
      }
    }
    relatedStrains: allStrapiStrain(
      limit: 3
      sort: { fields: [updated_at], order: DESC }
      filter: {
        strapiId: { ne: $id }
        type: { eq: $type }
      }
    ) {
      edges {
        node {
          name
          slug
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  ${tw`flex flex-col mx-auto max-w-screen-md items-center pt-16 px-6`}
  ${tw`md:(px-8)`}
`

const MainImage = styled(Img)`
  ${tw`w-full mx-auto mb-8`}
`

const Type = styled.span`
  color: #052f5f;
  ${tw`font-semibold`}
`

const Divisor = styled(Type)`
  ${tw`mx-2`}
`

const HighCBD = styled.span`
  color: #f9627d;
  ${tw`font-semibold`}
`

const Title = styled.h1`
  ${tw`text-3xl font-bold tracking-tight leading-10`}
  ${tw`md:(text-3xl leading-relaxed)`}
`

const Content = styled.section`
  ${tw`prose prose max-w-none text-justify leading-6 antialiased`}
`

const RelatedStrains = styled.div`
  ${tw`w-full mb-16`}
`

const RelatedStrainsList = styled.ul`
  ${tw`w-full grid grid-cols-1 gap-10`}
  ${tw`md:(grid-cols-2)`}
  ${tw`lg:(grid-cols-3)`}
`

const StrainDetail = (props) => {
  const Layout = props.pageContext.country === 'US' ? NewMainLayout : MainLayout;

  const {
    data: { strain, relatedStrains },
  } = props

  const [isCTAVisible, setIsCTAVisible] = useState(false)

  const [thc, cbd] = strain.cannabinoids
    .replace(/\n|\s/gi, '')
    .replace(/((.*)THC)?((.*)CBD)?/i, '$2|$4')
    .split('|')

  return (
    <Layout>
      <SEO
        title={`${strain.name} - Cannabis Strain`}
        description={strain.childMarkdownRemark.excerpt}
        image={strain.image ? strain.image.childImageSharp.fluid.src : null}
      />

      <Wrapper>
        <CTA isVisible={isCTAVisible} setIsVisible={setIsCTAVisible} />

        <article
          tw="w-full mb-16"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            {strain.image && (
              <MainImage
                fluid={strain.image.childImageSharp.fluid}
                title={`Strain: ${strain.name}`}
                alt={`Strain: ${strain.name}`}
                itemProp="image"
              />
            )}
            <div tw="flex">
              <Type>{strain.type}</Type>
              {strain.highCbd && (
                <>
                  <Divisor>|</Divisor>
                  <HighCBD>High CBD</HighCBD>
                </>
              )}
            </div>
            <Title itemProp="headline">{strain.name}</Title>
            <div tw="flex mb-8 text-lg">
              {thc && (
                <>
                  <strong tw="mr-2">THC:</strong>
                  <span tw="mr-2">{thc}</span>
                </>
              )}
              {cbd && (
                <>
                  <strong tw="mr-2">CBD:</strong>
                  <span>{cbd}</span>
                </>
              )}
            </div>
          </header>

          <Content
            itemProp="articleBody"
            dangerouslySetInnerHTML={{
              __html: strain.childMarkdownRemark.html,
            }}
          />
        </article>

        {relatedStrains.edges.length > 0 && (
          <RelatedStrains>
            <h4 tw="font-semibold mb-4">Similar Strains:</h4>
            <RelatedStrainsList>
              {relatedStrains.edges.map(({ node: relatedStrain }) => (
                <li key={relatedStrain.slug}>
                  <Link
                    to={`/strains/${relatedStrain.slug}`}
                    title={`Strain: ${relatedStrain.name}`}
                    tw="block"
                  >
                    {relatedStrain.image && (
                      <Img
                        tw="h-full w-full mb-4"
                        fluid={{
                          ...relatedStrain.image.childImageSharp.fluid,
                          aspectRatio: 4 / 3,
                        }}
                        alt={relatedStrain.name}
                      />
                    )}
                  </Link>
                  <span tw="font-semibold">{relatedStrain.name}</span>
                </li>
              ))}
            </RelatedStrainsList>
          </RelatedStrains>
        )}

        <CTA
          isVisible={isCTAVisible}
          setIsVisible={setIsCTAVisible}
          doNotFetch
        />
      </Wrapper>
    </Layout>
  )
}

export default StrainDetail
